// Imports
import { gsap } from 'gsap';

// # * • - • * > - • * < < - - # - ≥ - ≤ • * # - < - - # - ≥ - ≤ • * # - 
// Pixel Transition Animation
// # * • - • * > - • * < < - - # - ≥ - ≤ • * # - < - - # - ≥ - ≤ • * # - 
// Get window, document.documentElement, or document.body width/height
let
  w = window,
  d = document,
  e = d.documentElement,
  b = d.body,
  x = w.innerWidth || e.clientWidth || b.clientWidth,
  y = w.innerHeight || e.clientHeight || b.clientHeight;

// Pixel swipe function
// 7 "pixels" per row for mobile, not sure how many for desktop yet
// To-Do - - - - - - - - - - - - - - - -
// Add logic to test for mobile vs desktop and set pixelDivsPerRow accordingly
// var pixelDivsPerRow = 7;
let pixelDivsPerRow = 15;
// This will be the "pixel" width
let pixelDivWidth = x / pixelDivsPerRow;
// Make each "pixel" square
let pixelDivHeight = pixelDivWidth;
// Round up w/ Math.ceil to fill any partial row not filled, set parent div overflow to hidden
let numRowsPerViewHeight = Math.ceil(y / pixelDivHeight);

// Logs
// console.log(x + ' × ' + y);
// console.log("pixelDivWidth: " + pixelDivWidth);
// console.log("pixelDivHeight: " + pixelDivHeight);
// console.log("numRowsPerViewHeight: " + numRowsPerViewHeight);

// Logic to popluate pixel-transition parent div with pixel divs
function createPixels() {
  let pixelTransitionWrap = d.getElementById("pixel-transition-wrap");

  for (let j = 0; j < numRowsPerViewHeight; j++) {
    let pixelRow = d.createElement("div");
    pixelRow.className = 'pixel-row';

    for (let i = 0; i < pixelDivsPerRow; i++) {
      // create a new pixel
      let pixelDiv = d.createElement("div");
      // give it some class
      pixelDiv.className = 'pixel';
      // add the newly created pixelDiv to pixelRow
      pixelRow.appendChild(pixelDiv);
    };
    pixelTransitionWrap.appendChild(pixelRow);
  }
}

function randomNum(min, max) {
  return Math.random() * (min - max) + min;
}

// Timeline animation / loop to randomly show/hide each pixel div
// 1a. Show all ".pixel" elements
function showAllPixels() {
  // console.log("showAllPixels");

  let allPixels = d.querySelectorAll(".pixel");

  for (var i = 0; i < allPixels.length; i++) {
    gsap.to(allPixels[i], {
      autoAlpha: 1,
      delay: randomNum(0.8, 1.5),
      duration: 0
    });
  }
}

// 1b. Hide all ".pixel" elements
function hideAllPixels() {
  // console.log("hideAllPixels");

  let allPixels = d.querySelectorAll(".pixel");

  for (let i = 0; i < allPixels.length; i++) {
    gsap.to(allPixels[i], {
      autoAlpha: 0,
      delay: randomNum(0.8, 1.5),
      duration: 0
    });
  }
}

export { createPixels, showAllPixels, hideAllPixels };