// Imports
import Highway from '@dogstudio/highway';
// Import Highway Custom Renderers
import HomeRenderer from './home-renderer.js';
import ContactRenderer from './contact-renderer.js';
// Import Highway Custom Transitions
import HomeTransition from './home-transition';
import ContactTransition from './contact-transition';
import { gsap } from 'gsap';
import { createPixels } from './pixel-takeover';

// Navigation Funkies
const menuBtn = document.getElementById("menu-button");
const menuBtnText = document.getElementById("menu-button-text");
let menuIsOpen = false;

menuBtn.addEventListener("click", contactPageOpenerOfMadness);

function contactPageOpenerOfMadness() {
  if (!menuIsOpen) {
    gsap.to(menuBtnText, { duration: 0.314, rotation: 45 });
    menuIsOpen = !menuIsOpen;
    // console.log("menuIsOpen: " + menuIsOpen);
    menuBtnText.href = "index.html";
    // console.log(menuBtnText.href);
    // showAllPixels();
  } else {
    gsap.to(menuBtnText, { duration: 0.314, rotation: 0, delay: 0.2 });
    menuIsOpen = !menuIsOpen;
    // console.log("menuIsOpen: " + menuIsOpen);
    menuBtnText.href = "contact.html";
    // console.log(menuBtnText.href);
    // hideAllPixels();
  }
}

createPixels();

// Highway for Page Transitions
const H = new Highway.Core({
  renderers: {
    home: HomeRenderer,
    contact: ContactRenderer
  },
  transitions: {
    home: HomeTransition,
    contact: ContactTransition
    // default: goes here...
  }
});