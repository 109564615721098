// Imports
import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';

// Class Defs
class HomeRenderer extends Highway.Renderer {
  onEnter() {
    // ...
    // console.log('HomeRenderer onEnter');
    // Push Button Vars
    const piGame = document.getElementById('work-pi'),
      btnPushAnim = document.getElementById('btn-push-anim'),
      btnPressed = document.getElementById('btn-pressed'),
      btnUnpressed = document.getElementById('btn-unpressed');
    // PI Game Vars
    let gameBtnArr = [],
      lis = document.querySelectorAll('.pi-game-btn'),
      gameCursor = document.querySelector('.pi-game-cursor'),
      underscorey = document.querySelector('.underscorey'),
      piGameImgs = document.querySelector('.pi-game-images-wrap'),
      piGameBtns = document.querySelector('.pi-game-letter-buttons'),
      blackout = document.querySelector('.pi-game-blackout');
    // Blackhole
    const blackhole = document.getElementById('blackhole');
    // Inidividual Game Animation Vars
    const bake = document.getElementById('bake'),
      steam = document.getElementById('steam');
    // CAKE
    const cake = document.getElementById('cake'),
      flames1 = document.getElementById('flames1'),
      flames2 = document.getElementById('flames2');
    // FAKE
    const bill = document.getElementById('bill'),
      sharpie = document.getElementById('sharpie'),
      ink = document.getElementById('ink');
    // JAKE
    const jake = document.getElementById('jake'),
      eyebrow = document.getElementById('jake-eyebrow'),
      mouthcorners = document.getElementById('jake-mouthcorners');
    // LAKE
    const lake = document.getElementById('lake'),
      fish = document.getElementById('fish'),
      fishSmile = document.getElementById('fishSmile'),
      hook = document.getElementById('hook');
    // MAKE
    const makeAll = document.getElementById('makeAll'),
      make = document.getElementById('make'),
      banner = document.getElementById('banner'),
      bannerTails = document.getElementById('banner-tails');
    // MAKE-TEXT VARS
    const makeText = document.getElementById('make-text');
    let makeTextArray = makeText.getElementsByTagName('rect');
    let makeLetterArray = [].slice.call(makeTextArray);
    // GOOD-TEXT VARS
    const goodText = document.getElementById('good-text');
    let goodTextArray = goodText.getElementsByTagName('rect');
    let goodLetterArray = [].slice.call(goodTextArray);
    // WORK-TEXT VARS
    const workText = document.getElementById('work-text');
    let workTextArray = workText.getElementsByTagName('rect');
    let workLetterArray = [].slice.call(workTextArray);
    // RAKE
    const rakeAll = document.getElementById('rakeAll'),
      rake = document.getElementById('rake'),
      grooves = document.getElementById('grooves');
    // SAKE
    const sake = document.getElementById('sake'),
      stroke2ClipRect = document.getElementById('stroke2ClipRect'),
      stroke3ClipRect = document.getElementById('stroke3ClipRect'),
      stroke4ClipRect = document.getElementById('stroke4ClipRect'),
      stroke5ClipRect = document.getElementById('stroke5ClipRect'),
      stroke6ClipRect = document.getElementById('stroke6ClipRect'),
      stroke7ClipRect = document.getElementById('stroke7ClipRect'),
      stroke8ClipRect = document.getElementById('stroke8ClipRect'),
      stroke9ClipRect = document.getElementById('stroke9ClipRect'),
      stroke10ClipRect = document.getElementById('stroke10ClipRect');
    // TAKE
    const take = document.getElementById('take'),
      casio = document.getElementById('casio'),
      handOpen = document.getElementById('handOpen'),
      handClosed = document.getElementById('handClosed'),
      casioOnWrist = document.getElementById('casioOnWrist');
    // WAKE
    const wake = document.getElementById('wake'),
      lidFull = document.getElementById('lidfull'),
      lidHalf = document.getElementById('lidhalf'),
      lashes = document.getElementById('lashes');
    // WAKE SUB VARS - DROPS LEFT
    const dropsLeft = document.getElementById('bloodshot-left');
    let dropsLeftArray = dropsLeft.getElementsByTagName('rect');
    let dropLeftArray = [].slice.call(dropsLeftArray);
    // WAKE SUB VARS - DROPS RIGHT
    const dropsRight = document.getElementById('bloodshot-right');
    let dropsRightArray = dropsRight.getElementsByTagName('rect');
    let dropRightArray = [].slice.call(dropsRightArray);

    // KEYPRESSED BOO VARS
    let bPressed = false,
      cPressed = false,
      fPressed = false,
      jPressed = false,
      lPressed = false,
      mPressed = false,
      rPressed = false,
      sPressed = false,
      tPressed = false,
      wPressed = false;

    // Random Placey Place Vars
    // var speed=1200;
    let leftVal = [];
    let topVal = [];
    let rpcObj; // random placement container object 
    let rpcW; // random placement container outerWidth
    let rpcH; // random placement container outerHeight
    let i; // for loop integer 
    // var objs = document.getElementsByTagName('div');
    let bit = 50;

    // PI FUNKYS
    function showPi() {
      initPiGame();
      // btnPushAnim.classList.remove('remove-from-dom');
      // gsap.to(btnPushAnim, { duration: 0, autoAlpha: 1 });
      gsap.to(piGame, { css: { opacity: 1 }, ease: "power4.easeOut", delay: 5.4, duration: 1, onComplete: removeBtnPushAnim });
    }
    showPi();

    function removeBtnPushAnim() {
      // btnPushAnim.classList.add('remove-from-dom');
      // gsap.to(btnPushAnim, { duration: 0, autoAlpha: 0 });
      btnUnpressed.addEventListener("click", btnUnpressedClick);
      gsap.to(btnUnpressed, { duration: 0, opacity: 1 });
      gsap.to(btnPressed, { duration: 0, opacity: 1 });
    }

    // INIT FUNKY BREWSTER
    function initPiGame() {
      // populate buttons
      populateBtnArr(lis);
      // shopw game buttons
      showGameBtns(gameBtnArr);
      // place buttons randomly within button container
      randomyPlaceyPlace();
      // WHAT'S HAPNIN? LISTEN...
      areYouEvenListeningToMe(gameBtnArr);
      window.addEventListener('keydown', keyListener);
    }

    // POPULATE BUTTON ARRAY FUNKIE BOOSTER
    function populateBtnArr(nodeListArg) {
      // populate bameBtnArr with the nodeList returned from lis
      for (var i = 0; i < nodeListArg.length; i++) {
        gameBtnArr.push(nodeListArg[i]);
      }
    }

    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // FUNKY BREWSTERS
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // CURSOR BLINK TIMELINE
    var tlCursor = gsap.timeline({ repeat: -1 })
    tlCursor.to(gameCursor, { duration: 0, opacity: 0 }, "+=0.314")
      .to(gameCursor, { duration: 0, opacity: 1 }, "+=0.314");

    // SHOW GAME BUTTONS FLUNKY ROOSTER
    function showGameBtns(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].classList.add('show-em');
      }
    }

    // ADD CLICK LISTENER PER ARRAY ELEMENT FARTY POOPSTER
    function areYouEvenListeningToMe(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].addEventListener("click", clickListener);
      }
    }

    // CURSOR - HIDE FLONY SHREWDSTER
    function hideCursor(arg) {
      // remove blinking cursor for a bit and change first letter of _ake to this innerHTML
      gameCursor.classList.add('hide-em');
      underscorey.innerHTML = arg.innerHTML;
    }

    // SETS OPACITY TO 0 FOR PI-GAME SVG ANIM ARRAYS FONIE CELLPLAN
    function opacityDowner(element) {
      element.style.opacity = '0';
    }

    // BUTTON DISABLER FIGGY PIESTER
    function btnDisabler(arg) {
      // UX - change the background-color to look disabled and remove event listener for this button
      arg.classList.add('disabled');
      arg.removeEventListener("click", clickListener);
    }

    // REMOVE CLICK LISTENER FLIPPY SWITCHER
    function removeClickListener() {
      for (var i = 0; i < gameBtnArr.length; i++) {
        // console.log('clickListener removed from gameBtnArr buttons');
        gameBtnArr[i].removeEventListener("click", clickListener);
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // INIT FLOPPY SHROOMSTERS
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // REMOVE KEY LISTENER FLICKITY FLACKER
    function removeKeyListener() {
      // console.log('keyListener removed from window');
      window.removeEventListener('keydown', keyListener);
    }

    // REMOVE CLICK AND KEY LISTENER FLACKY FLICKSTER
    function removeClickKeyListeners() {
      removeClickListener();
      removeKeyListener();
    }

    // DE-ACTIVATE PI-GAME-BTNS FIGGY POPSTAR
    function deactivateBtns() {
      // make buttons lower than images and blackout
      piGameBtns.classList.add('zIndexEight');
      // gsap.to(piGameBtns, { duration: 0.314, opacity: 0.314 });
      // set blackhole opacity back to 0.314
      gsap.to(blackhole, { duration: 0.314, opacity: 1 });
      // show blackout div to "deactivate" buttons
      blackout.classList.add('show-em');
      gsap.to(blackout, { duration: 0.314, opacity: 1 });
      // make game images higher than buttons and blackout
      piGameImgs.classList.add('zIndexNine');
      // REMOVE PUSH BTN LISTENER DURING ANIMATIONS
      btnUnpressed.removeEventListener("click", btnUnpressedClick);
      gsap.to(btnUnpressed, { duration: 0, opacity: 0.314 });
      gsap.to(btnPressed, { duration: 0, opacity: 0 });
    }

    // RE-ACTIVATE PI-GAME-BTNS FOGGY JITTERY
    function reactivateBtns() {
      // make buttons higher thank images and blackout
      piGameBtns.classList.remove('zIndexEight');
      // gsap.to(piGameBtns, { duration: 0, opacity: 1 });
      // set blackhole opacity back to 0.314
      gsap.to(blackhole, { duration: 0, opacity: 0.314 });
      // hide blackout div to "reactivate" buttons
      blackout.classList.remove('show-em');
      gsap.to(blackout, { duration: 0, opacity: 0 });
      // make game images lower than buttons and blackout
      piGameImgs.classList.remove('zIndexNine');
      // ADD PUSH BTN LISTENER AFTER ANIMATIONS
    }

    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // RANDOM BUTTON PLACEMENT
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // RANDOM PLACEMENT FUNKIE
    function randomyPlaceyPlace() {
      rpcObj = document.getElementById('pi-btn-container');
      rpcW = rpcObj.offsetWidth;
      rpcH = rpcObj.offsetHeight;

      // FOR LOOP
      for (i = 0; i < gameBtnArr.length; i++) {

        leftVal[i] = Math.floor(Math.random() * rpcW);
        topVal[i] = Math.floor(Math.random() * rpcH);
        // DO NOT DELETE
        // previous way to set zIndex of letter buttons, keep for reference
        // gameBtnArr[i].style.zIndex = Math.floor(Math.random() * rpcW);
        // new way to display zIndex of letter buttons under the navigation button
        gameBtnArr[i].style.zIndex = 12;

        if (leftVal[i] >= rpcW - gameBtnArr[i].offsetWidth) {
          leftVal[i] = rpcW - gameBtnArr[i].offsetWidth - bit;
        }
        if (leftVal[i] <= 0) {
          leftVal[i] = 0 + bit;
        }
        if (topVal[i] >= rpcH - gameBtnArr[i].offsetHeight) {
          topVal[i] = rpcH - gameBtnArr[i].offsetHeight - bit;
        }
        if (topVal[i] <= 0) {
          topVal[i] = 0 + bit;
        }
        gameBtnArr[i].style.left = leftVal[i] + 'px';
        gameBtnArr[i].style.top = topVal[i] + 'px';
        gameBtnArr[i].className = 'position';
      }
    }

    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // CLICK LISTENER
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function clickListener() {
      // GET STRING VAL FROM BTN
      var alf = this.innerHTML;

      // DEACTIVATE
      deactivateBtns();

      if (alf === 'b' && !bPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        bPressed = true;
        bakeAnim();
      }
      else if (alf === 'c' && !cPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        cPressed = true;
        cakeAnim();
      }
      else if (alf === 'f' && !fPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        fPressed = true;
        fakeAnim();
      }
      else if (alf === 'j' && !jPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        jPressed = true;
        jakeAnim();
      }
      else if (alf === 'l' && !lPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        lPressed = true;
        lakeAnim();
      }
      else if (alf === 'm' && !mPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        mPressed = true;
        makeAnim();
      }
      else if (alf === 'r' && !rPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        rPressed = true;
        rakeAnim();
      }
      else if (alf === 's' && !sPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        sPressed = true;
        sakeAnim();
      }
      else if (alf === 't' && !tPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        tPressed = true;
        takeAnim();
      }
      else if (alf === 'w' && !wPressed) {
        removeClickKeyListeners();
        hideCursor(this);
        btnDisabler(this);
        wPressed = true;
        wakeAnim();
      }
    }

    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // KEY LISTENER
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function keyListener(event) {
      // VARS
      var b = event.charCode || event.keyCode;
      var c = event.charCode || event.keyCode;
      var f = event.charCode || event.keyCode;
      var j = event.charCode || event.keyCode;
      var l = event.charCode || event.keyCode;
      var m = event.charCode || event.keyCode;
      var r = event.charCode || event.keyCode;
      var s = event.charCode || event.keyCode;
      var t = event.charCode || event.keyCode;
      var w = event.charCode || event.keyCode;

      // BAKE ANIM
      if (bPressed === false) {
        if (b == 98 || b == 66) {  // b is 98, B is 66
          removeClickKeyListeners();
          deactivateBtns();
          bPressed = true;
          var alf = gameBtnArr[0];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          bakeAnim();
        }
      }
      // CAKE ANIM
      if (cPressed === false) {
        if (c == 99 || c == 67) {  // c is 99, C is 67
          removeClickKeyListeners();
          deactivateBtns();
          cPressed = true;
          var alf = gameBtnArr[1];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          cakeAnim();
        }
      }
      // FAKE ANIM
      if (fPressed === false) {
        if (f == 102 || f == 70) {  // f is 102, F is 70
          removeClickKeyListeners();
          deactivateBtns();
          fPressed = true;
          var alf = gameBtnArr[2];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          fakeAnim();
        }
      }
      // JAKE ANIM
      if (jPressed === false) {
        if (j == 106 || j == 74) {  // j is 106, J is 74
          removeClickKeyListeners();
          deactivateBtns();
          jPressed = true;
          var alf = gameBtnArr[3];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          jakeAnim();
        }
      }
      // LAKE ANIM
      if (lPressed === false) {
        if (l == 108 || l == 76) {  // l is 108, L is 76
          removeClickKeyListeners();
          deactivateBtns();
          lPressed = true;
          var alf = gameBtnArr[4];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          lakeAnim();
        }
      }
      // MAKE ANIM
      if (mPressed === false) {
        if (m == 109 || m == 77) {  // m is 109, M is 77
          removeClickKeyListeners();
          deactivateBtns();
          mPressed = true;
          var alf = gameBtnArr[5];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          makeAnim();
        }
      }
      // RAKE ANIM
      if (rPressed === false) {
        if (r == 114 || r == 82) {  // r is 114, L is 82
          removeClickKeyListeners();
          deactivateBtns();
          rPressed = true;
          var alf = gameBtnArr[6];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          rakeAnim();
        }
      }
      // SAKE ANIM
      if (sPressed === false) {
        if (s == 115 || s == 83) {  // s is 115, S is 83
          removeClickKeyListeners();
          deactivateBtns();
          sPressed = true;
          var alf = gameBtnArr[7];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          sakeAnim();
        }
      }
      // TAKE ANIM
      if (tPressed === false) {
        if (t == 116 || t == 84) {  // t is 116, T is 84
          removeClickKeyListeners();
          deactivateBtns();
          tPressed = true;
          var alf = gameBtnArr[8];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          takeAnim();
        }
      }
      // WAKE ANIM
      if (wPressed === false) {
        if (w == 119 || w == 87) {  // w is 119, W is 87
          removeClickKeyListeners();
          deactivateBtns();
          wPressed = true;
          var alf = gameBtnArr[9];
          alf.classList.add('disabled');
          gameCursor.classList.add('hide-em');
          underscorey.innerHTML = alf.innerHTML;
          wakeAnim();
        }
      }
    }

    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    // INDIVIDUAL SVG ANIMATIONS
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -

    // BAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function bakeAnim() {
      var tl = gsap.timeline();
      tl.to(bake, { css: { top: "0%" }, duration: 0.5, ease: "back.out(1)" })
        .to(steam, { duration: 1.5, opacity: 1, ease: "back.out(1)" })
        .to(heat, { duration: 1.5, opacity: 1, ease: "none" }, "-=1.5")
        .call(steamUpDowner, null, this);
      // STEAM ANIM
      function steamUpDowner() {
        var tl1 = gsap.timeline({ repeat: 3 });
        tl1.to(steam, { duration: 0, y: "-4px" }, "+=0.2")
          .to(steam, { duration: 0, y: "0" }, "+=0.2");
      }
      // resetter and forgetter
      setTimeout(function () {
        bakeAnimOut();
        resetListeners();
      }, 5000);
    }

    // CAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function cakeAnim() {
      var tl = gsap.timeline();
      tl.to(cake, { css: { top: "0%" }, duration: 0.5, ease: "back.out(1)" })
        .to(flames1, { duration: 1, opacity: 1 }, "-=1")
        .to(flames2, { duration: 1, opacity: 1 }, "-=1")
        .call(flamesUpDowner, null, this);
      // FLAMES FUNK
      function flamesUpDowner() {
        // flames 1
        gsap.to(flames1, { duration: 0, y: "3px" })
        gsap.to(flames1, { duration: 0, y: "0", delay: 0.5 })
        gsap.to(flames1, { duration: 0, y: "-3px", delay: 1 })
        gsap.to(flames1, { duration: 0, y: "0px", delay: 1.5 })
        gsap.to(flames1, { duration: 0, y: "3px", delay: 2 })
        gsap.to(flames1, { duration: 0, y: "0px", delay: 2.5 })
        gsap.to(flames1, { duration: 0, y: "-3px", delay: 3 })
        gsap.to(flames1, { duration: 0, y: "0px", delay: 3.5 });
        // flames 2
        gsap.to(flames2, { duration: 0, y: "-3px" })
        gsap.to(flames2, { duration: 0, y: "0", delay: 0.5 })
        gsap.to(flames2, { duration: 0, y: "3px", delay: 1 })
        gsap.to(flames2, { duration: 0, y: "0px", delay: 1.5 })
        gsap.to(flames2, { duration: 0, y: "-3px", delay: 2 })
        gsap.to(flames2, { duration: 0, y: "0px", delay: 2.5 })
        gsap.to(flames2, { duration: 0, y: "3px", delay: 3 })
        gsap.to(flames2, { duration: 0, y: "0px", delay: 3.5 });
      }
      // resetter and forgetter
      setTimeout(function () {
        cakeAnimOut();
        resetListeners();
      }, 5000);
    }

    // FAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function fakeAnim() {
      var tl = gsap.timeline();
      tl.to(bill, { duration: 0.5, css: { top: "5%" }, ease: "back.out(1)" })
        .to(sharpie, { duration: 1, css: { top: "-31.4%", left: "2%" }, ease: "power4.out" }, '+=0.25')
        .to(sharpie, { duration: 0.25, css: { top: "-63%", left: "0%" }, ease: "none" })
        .to(ink, { duration: 0.5, opacity: 1 }, '-=0.25')
        .to(sharpie, { duration: 0.5, css: { top: "-100%" }, ease: "power4.out" })
        .to(ink, { duration: 0.75, fill: "#F51AA5" }, '-=0.5')
        .call(fakeUpDowner, null, this);
      // up down function
      function fakeUpDowner() {
        gsap.to(bill, { duration: 1, css: { top: "8%" }, repeat: 1, yoyo: true, ease: "none" });
      }
      // resetter and forgetter
      setTimeout(function () {
        fakeAnimOut();
        resetListeners();
      }, 6000);
    }

    // JAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function jakeAnim() {
      var tl = gsap.timeline();
      tl.to(jake, { duration: 0.5, css: { top: "0%" }, ease: "back.out(1)" })
        .call(jakeUpDowner, null, this);
      // up down function
      function jakeUpDowner() {
        gsap.to(jake, { duration: 1, css: { top: "2.5%" }, repeat: 1, yoyo: true, ease: "none", onComplete: jakeSmile });
      }
      // jakeSmile
      function jakeSmile() {
        var tlSmile = gsap.timeline();
        tlSmile.to(eyebrow, { duration: 1, css: { top: "0%" }, ease: "power4.out" })
          .to(mouthcorners, { duration: 1, css: { top: "-2.5%" }, ease: "power4.out" }, "-=1");
      }
      // resetter and forgetter
      setTimeout(function () {
        jakeAnimOut();
        resetListeners();
      }, 3900);
    }

    // LAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function lakeAnim() {
      var tl = gsap.timeline();
      tl.to(fish, { duration: 0.75, css: { top: "77%" }, ease: "power4.out" })
        .to(hook, { duration: 0.75, css: { top: "0%" }, ease: "power4.out" })
        .to(hook, { duration: 0.75, css: { top: "-50%" }, ease: "power4.inOut" })
        .to(fish, { duration: 0.75, css: { top: "27%" }, ease: "power4.inOut" }, "-=0.75")
        .call(lakeUpDowner, null, this);
      // up down function
      function lakeUpDowner() {
        gsap.to(lake, { duration: 1, css: { top: "3.14%" }, repeat: 2, yoyo: true, ease: "none", onComplete: fishSmiler });
      }
      // fishSmiler
      function fishSmiler() {
        gsap.to(fishSmile, { duration: 0, opacity: 1 });
      }
      // resetter and forgetter
      setTimeout(function () {
        lakeAnimOut();
        resetListeners();
      }, 6000);
    }

    // MAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function makeAnim() {
      // OPACITY TO 0 FOR MAKE, GOOD, AND WORK TEXT AND APPLY TO EACH SIDE
      makeLetterArray.forEach(opacityDowner);
      goodLetterArray.forEach(opacityDowner);
      workLetterArray.forEach(opacityDowner);

      var tl = gsap.timeline({ repeat: 0 });
      tl.to(make, { duration: 0, opacity: 1 })
        .call(makeTextAnim, null, this)
        .call(goodTextAnim, null, this)
        .call(workTextAnim, null, this)
        .to(banner, { duration: 2, css: { top: "0%" }, ease: "none" }, "+=2")
        .to(bannerTails, { duration: 2, css: { top: "0%" }, ease: "none" }, "-=2")
        .call(makeUpDowner, null, this);
      // MAKE-TEXT ANIM - iterates through, setTimeout per element in array, and change opacity
      function makeTextAnim() {
        for (var i = 0; i < makeTextArray.length; i++) {
          (function (i) {
            setTimeout(function () {
              var makeLetter = makeLetterArray[i];
              gsap.to(makeLetter, { duration: 0, opacity: 1 });
            }, 10 * i); // sets amount of time between each drop opacity
          })(i); // pass here
        }
      }
      // GOOD-TEXT ANIM - iterates through, setTimeout per element in array, and change opacity
      function goodTextAnim() {
        for (var i = 0; i < goodTextArray.length; i++) {
          (function (i) {
            setTimeout(function () {
              var goodLetter = goodLetterArray[i];
              gsap.to(goodLetter, { duration: 0, opacity: 1 });
            }, 11 * i); // sets amount of time between each drop opacity
          })(i); // pass here
        }
      }
      // WORK-TEXT ANIM - iterates through, setTimeout per element in array, and change opacity
      function workTextAnim() {
        for (var i = 0; i < workTextArray.length; i++) {
          (function (i) {
            setTimeout(function () {
              var workLetter = workLetterArray[i];
              gsap.to(workLetter, { duration: 0, opacity: 1 });
            }, 10 * i); // sets amount of time between each drop opacity
          })(i); // pass here
        }
      }
      // up down function
      function makeUpDowner() {
        gsap.to(banner, { duration: 2, css: { top: "54%" }, repeat: 2, yoyo: true, ease: "none", onComplete: bannerMidPos });
        gsap.to(bannerTails, { duration: 2, css: { top: "54%" }, repeat: 2, yoyo: true, ease: "none", onComplete: bannerTailsMidPos });
      }
      function bannerMidPos() {
        gsap.to(banner, { duration: 1.5, css: { top: "27%" }, ease: "none" });
      }
      function bannerTailsMidPos() {
        gsap.to(bannerTails, { duration: 1.5, css: { top: "27%" }, ease: "none" });
      }
      // resetter and forgetter
      setTimeout(function () {
        makeAnimOut();
        resetListeners();
      }, 12000);
    }

    // RAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function rakeAnim() {
      var tl = gsap.timeline();
      tl.to(rake, { duration: 1, css: { top: "60%" }, ease: "power4.out" })
        .to(rake, { duration: 0.75, css: { top: "5%" }, ease: "power4.inOut" })
        .to(grooves, { duration: 0.75, css: { top: "37%" }, ease: "power4.inOut" }, "-=0.75")
        .call(rakeUpDowner, null, this);
      // up down function
      function rakeUpDowner() {
        gsap.to(rake, { duration: 1, css: { top: "8.14%" }, repeat: 2, yoyo: true, ease: "none" });
      }
      // resetter and forgetter
      setTimeout(function () {
        rakeAnimOut();
        resetListeners();
      }, 5000);
    }

    // SAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function sakeAnim() {
      var tl = gsap.timeline();
      tl.to(sake, { duration: 0.5, css: { top: "0%" }, ease: "back.out(1)" })
        .set(stroke2ClipRect, { attr: { width: 0, x: 0 } })
        .to(stroke2ClipRect, { duration: 0.2, attr: { width: "100%", x: 0, ease: "power4.out" } })
        .set(stroke3ClipRect, { attr: { width: 0, x: 0 } })
        .to(stroke3ClipRect, { duration: 0.2, attr: { width: "100%", x: 0, ease: "power4.out" } })
        .set(stroke4ClipRect, { attr: { width: 0, x: 0 } })
        .to(stroke4ClipRect, { duration: 0.2, attr: { width: "100%", x: 0, ease: "power4.out" } })
        .set(stroke5ClipRect, { attr: { height: 0, x: 0 } })
        .to(stroke5ClipRect, { duration: 0.2, attr: { height: "100%", x: 0, ease: "power4.out" } })
        .set(stroke7ClipRect, { attr: { width: 0, height: "48.6%", x: 0, y: 0 } })
        .to(stroke7ClipRect, { duration: 0.2, attr: { width: "100%", height: "48.6%", x: 0, y: 0, ease: "power4.out" } })
        .to(stroke7ClipRect, { duration: 0.2, attr: { width: "100%", height: "100%", x: 0, y: 0, ease: "power4.out" } })
        .set(stroke8ClipRect, { attr: { width: "100%", height: 0, x: 0, y: 0 } })
        .to(stroke8ClipRect, { duration: 0.2, attr: { width: "100%", height: "100%", x: 0, y: 0, ease: "power4.out" } })
        .set(stroke6ClipRect, { attr: { height: 0, x: 0, y: 0 } })
        .to(stroke6ClipRect, { duration: 0.2, attr: { height: "100%", x: 0, y: 0, ease: "power4.out" } })
        .set(stroke9ClipRect, { attr: { width: 0, x: 0 } })
        .to(stroke9ClipRect, { duration: 0.2, attr: { width: "100%", x: 0, ease: "power4.out" } })
        .set(stroke10ClipRect, { attr: { width: 0, x: 0 } })
        .to(stroke10ClipRect, { duration: 0.2, attr: { width: "100%", x: 0, ease: "power4.out" } })
        .call(sakeUpDowner, null, this);
      // up down function
      function sakeUpDowner() {
        var tlSakeUpDowner = gsap.timeline({ repeat: 1 });
        tlSakeUpDowner.to(sake, { duration: 1, y: "-3.14%", ease: "none" })
          .to(sake, { duration: 1, y: "0%", ease: "none" });
      }
      // resetter and forgetter
      setTimeout(function () {
        sakeAnimOut();
        resetListeners();
      }, 7000);
    }

    // TAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function takeAnim() {
      var tl = gsap.timeline();
      tl.to(casio, { duration: 0.5, css: { top: "0%" }, ease: "back.out(1)" })
        .call(takeUpDowner, null, this)
        .to(handOpen, { duration: 0.75, css: { top: "0%" }, ease: "power4.out" }, "+=1")
        .to(handOpen, { duration: 0, opacity: 0 }, "+=0.21")
        .to(casio, { duration: 0, opacity: 0 })
        .to(handClosed, { duration: 0, opacity: 1 })
        .to(handClosed, { duration: 0.75, css: { top: "100%" }, ease: "power4.out" }, "+=0.314")
        .to(casioOnWrist, { duration: 0, opacity: 1 })
        .to(handClosed, { duration: 0.75, css: { top: "0%" } }, "+=0.5");
      // up down function
      function takeUpDowner() {
        var tlTakeUpDowner = gsap.timeline({ repeat: 1 });
        tlTakeUpDowner.to(take, { duration: 1, y: "3.14%", ease: "none" })
          .to(take, { duration: 1, y: "0%", ease: "none" });
      }
      // resetter and forgetter
      setTimeout(function () {
        takeAnimOut();
        resetListeners();
      }, 6900);
    }

    // WAKE ANIM
    // - - - - - --- - - -- - - - -  - - - - - - - - - -  - - - - -- -
    function wakeAnim() {
      // OPACITY TO 0 FOR VEINS AND APPLY TO EACH SIDE
      dropLeftArray.forEach(opacityDowner);
      dropRightArray.forEach(opacityDowner);

      var tl = gsap.timeline();
      tl.to(wake, { duration: 0.5, css: { top: "0%" }, ease: "back.out(1)" })
        .call(wakeUpDowner, null, this)
        .to(lidFull, { duration: 0, opacity: 1 }, "+=0.1")
        .to(lidFull, { duration: 0, opacity: 0 }, "+=0.314")
        .to(lidFull, { duration: 0, opacity: 1 }, "+=0.18")
        .to(lidFull, { duration: 0, opacity: 0 }, "+=0.314")
        .to(lidFull, { duration: 0, opacity: 1 }, "+=1.314")
        .to(lidFull, { duration: 0, opacity: 0 }, "+=0.9")
        .to(lidHalf, { duration: 0, opacity: 0 }, "+=0.05")
        .to(lashes, { duration: 0, opacity: 1 })
        // call veins anims
        .call(leftVeinsAnim, null, this, '+=0.21')
        .call(rightVeinsAnim, null, this, '+=0.314');
      // LEFT VEINS ANIM - iterates through, setTimeout per element in array, and change opacity
      function leftVeinsAnim() {
        for (var i = 0; i < dropsLeftArray.length; i++) {
          (function (i) {
            setTimeout(function () {
              var drop = dropLeftArray[i];
              gsap.to(drop, { duration: 0, opacity: 1 });
            }, 63 * i); // sets amount of time between each drop opacity
          })(i); // pass here
        }
      }
      // RIGHT VEINS ANIM - iterates through, setTimeout per element in array, and change opacity
      function rightVeinsAnim() {
        for (var i = 0; i < dropsRightArray.length; i++) {
          (function (i) {
            setTimeout(function () {
              var drop = dropRightArray[i];
              gsap.to(drop, { duration: 0, opacity: 1 });
            }, 72 * i); // sets amount of time between each drop opacity
          })(i); // pass here
        }
      }
      // up down function
      function wakeUpDowner() {
        gsap.to(wake, { duration: 1, css: { top: "3.14%" }, repeat: 5, yoyo: true, ease: "none" });
      }
      // resetter and forgetter
      setTimeout(function () {
        wakeAnimOut();
        resetListeners();
      }, 8100);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // ANIMATION RESETS
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    function resetListeners() {
      // RESET CURSOR
      underscorey.innerHTML = "_";
      gameCursor.classList.remove('hide-em');
      // ADD CLICKLISTENER TO GAME BTN ARRAY
      areYouEvenListeningToMe(gameBtnArr);
      // ADD KEYLISTENER TO WINDOW
      window.addEventListener('keydown', keyListener);
      btnUnpressed.addEventListener("click", btnUnpressedClick);
      gsap.to(btnUnpressed, { duration: 0, opacity: 1 });
      gsap.to(btnPressed, { duration: 0, opacity: 0 });
    }

    // ANIMATE EACH SVG OUT
    function bakeAnimOut() {
      gsap.to(bake, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function cakeAnimOut() {
      gsap.to(cake, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function fakeAnimOut() {
      gsap.to(bill, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function jakeAnimOut() {
      gsap.to(jake, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function lakeAnimOut() {
      gsap.to(lake, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function makeAnimOut() {
      gsap.to(makeAll, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function rakeAnimOut() {
      gsap.to(rakeAll, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function sakeAnimOut() {
      gsap.to(sake, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function takeAnimOut() {
      gsap.to(take, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }
    function wakeAnimOut() {
      gsap.to(wake, { duration: 0.314, opacity: 0, onComplete: reactivateBtns });
    }

    // RESET EACH SVG ANIM
    function resetEachSVGAnim() {
      // BAKE RESETS
      gsap.to(bake, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.to(steam, { duration: 0, opacity: 0 });
      gsap.to(heat, { duration: 0, opacity: 0 });
      // CAKE RESETS
      gsap.to(cake, { duration: 0, opacity: 1 });
      gsap.to(cake, { duration: 0, css: { top: "100%" } });
      gsap.to(flames1, { duration: 0, opacity: 0 });
      gsap.to(flames2, { duration: 0, opacity: 0 });
      // FAKE RESETS
      gsap.to(bill, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.to(sharpie, { duration: 0, css: { top: "-100%", left: "2%" } });
      gsap.to(ink, { duration: 0, opacity: 0, fill: "#000000" });
      // JAKE RESETS
      gsap.to(jake, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.to(eyebrow, { duration: 0, css: { top: "3%" } });
      gsap.to(mouthcorners, { duration: 0, css: { top: "0%" } });
      // LAKE RESETS
      gsap.to(lake, { duration: 0, css: { top: "0%", opacity: 1 } });
      gsap.to(fish, { duration: 0, css: { top: "100%" } });
      gsap.to(hook, { duration: 0, css: { top: "-100%" } });
      gsap.to(fishSmile, { duration: 0, opacity: 0 });
      // MAKE RESETS
      gsap.to(makeAll, { duration: 0, opacity: 1 });
      gsap.to(banner, { duration: 0, css: { top: "100%" } });
      gsap.to(bannerTails, { duration: 0, css: { top: "100%" } });
      makeLetterArray.forEach(opacityDowner);
      goodLetterArray.forEach(opacityDowner);
      workLetterArray.forEach(opacityDowner);
      // RAKE RESETS
      gsap.to(rakeAll, { duration: 0, opacity: 1 });
      gsap.to(rake, { duration: 0, css: { top: "-100%" } });
      gsap.to(grooves, { duration: 0, css: { top: "100%" } });
      // SAKE RESETS
      gsap.to(sake, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.set(stroke2ClipRect, { attr: { width: 0, x: 0 } });
      gsap.set(stroke3ClipRect, { attr: { width: 0, x: 0 } });
      gsap.set(stroke4ClipRect, { attr: { width: 0, x: 0 } });
      gsap.set(stroke5ClipRect, { attr: { height: 0, x: 0 } });
      gsap.set(stroke7ClipRect, { attr: { width: 0, height: "48.6%", x: 0, y: 0 } });
      gsap.set(stroke8ClipRect, { attr: { width: "100%", height: 0, x: 0, y: 0 } });
      gsap.set(stroke6ClipRect, { attr: { height: 0, x: 0, y: 0 } });
      gsap.set(stroke9ClipRect, { attr: { width: 0, x: 0 } });
      gsap.set(stroke10ClipRect, { attr: { width: 0, x: 0 } });
      // TAKE RESETS
      gsap.to(take, { duration: 0, css: { y: "0%", opacity: 1 } });
      gsap.to(casio, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.to(handOpen, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.to(handClosed, { duration: 0, css: { top: "0%", opacity: 0 } });
      gsap.to(casioOnWrist, { duration: 0, opacity: 0 });
      // WAKE RESETS
      gsap.to(wake, { duration: 0, css: { top: "100%", opacity: 1 } });
      gsap.to(lidFull, { duration: 0, opacity: 0 });
      gsap.to(lidHalf, { duration: 0, opacity: 1 });
      gsap.to(lashes, { duration: 0, opacity: 0 });
      dropLeftArray.forEach(opacityDowner);
      dropRightArray.forEach(opacityDowner);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RESET FLOPPY SHROOMSTERS
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RESET FREAKY BROOMSTER
    function resetPiGame() {
      // reset btn pressed booleans
      bPressed = cPressed = fPressed = jPressed = lPressed = mPressed = rPressed = sPressed = tPressed = wPressed = false;
      // reset gameBtnArr
      gameBtnArr = [];
      // populate buttons
      populateBtnArr(lis);
      // shopw game buttons
      showGameBtns(gameBtnArr);
      // place buttons randomly within button container
      randomyPlaceyPlace();
      // WHAT'S HAPNIN? LISTEN...
      areYouEvenListeningToMe(gameBtnArr);
      window.addEventListener('keydown', keyListener);
      // reactivate game buttons
      reactivateBtns();
      // reset eash svg animation
      resetEachSVGAnim();
    }

    // BTN FLIPKEYS - PI GAME FLOONKY FLEWSTERS
    function btnUnpressedClick() {
      let tlPushButton = gsap.timeline();

      tlPushButton.to(btnUnpressed, { duration: 0, opacity: 0 })
        .to(btnPressed, { duration: 0, opacity: 1 })
        .to(btnPushAnim, { duration: 0, opacity: 0 })
        .to(btnUnpressed, { duration: 0, opacity: 1 }, "+=0.12")
        .to(btnPressed, { duration: 0, opacity: 0 })
        .to(btnPushAnim, { duration: 0, opacity: 1 });

      resetPiGame();
      underscorey.innerHTML = "_";
      gameCursor.classList.remove('hide-em');
    }
    // THE END ISN'T JUST NEAR, IT'S HERE!    
  }
  onLeave() {
    // ...
    // console.log('HomeRenderer onLeave');
  }
  onEnterCompleted() {
    // ...
    // console.log('HomeRenderer onEnterCompleted');
  }
  onLeaveCompleted() {
    // ...
    // console.log('HomeRenderer onLeaveCompleted');
  }
}

// Export
export default HomeRenderer;