// Imports
import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

// Class Defs
class ContactRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    // console.log('ContactRenderer onEnter');
    // # * • - • * > - • * < < - - # - ≥ - ≤ • * # - < - - # - ≥ - ≤ • * # - 
    // PI logo reset
    // # * • - • * > - • * < < - - # - ≥ - ≤ • * # - < - - # - ≥ - ≤ • * # - 
    let hstem = document.getElementById("h-stem"),
      hcurve = document.getElementById("h-curve"),
      smilecurve = document.getElementById("smile-curve"),
      istem = document.getElementById("i-stem"),
      dot1 = document.getElementById("dot-1"),
      dot2 = document.getElementById("dot-2"),
      ptranscurve = document.getElementById("p-transition-curve"),
      pstem = document.getElementById("p-stem"),
      pcurve = document.getElementById("p-curve");

    // Intro animations
    let tlResetPi = gsap.timeline();

    function resetPI() {
      tlResetPi.set(istem, { opacity: 1, drawSVG: "70 200" })
        .set(hstem, { opacity: 1, drawSVG: "0% 100%" })
        .set(dot1, { opacity: 1, scaleX: 1, scaleY: 1, y: 0 })
        .set(dot2, { opacity: 1, scaleY: 0 })
        .set(hcurve, { opacity: 1, drawSVG: "0% 100%" })
        .set(smilecurve, { opacity: 1, drawSVG: "0%" })
        .set(ptranscurve, { opacity: 1, scaleX: 1, drawSVG: "0% 0%" })
        .set(pstem, { opacity: 1, drawSVG: "0% 0%" })
        .set(pcurve, { opacity: 1, drawSVG: "0% 0%" });
    }
    resetPI();

  }
  onLeave() {
    // console.log('ContactRenderer onLeave');
  }
  onEnterCompleted() {
    // console.log('ContactRenderer onEnterCompleted');
    // # * • - • * > - • * < < - - # - ≥ - ≤ • * # - < - - # - ≥ - ≤ • * # - 
    // PI logo animation
    // # * • - • * > - • * < < - - # - ≥ - ≤ • * # - < - - # - ≥ - ≤ • * # - 
    let hstem = document.getElementById("h-stem"),
      hcurve = document.getElementById("h-curve"),
      smilecurve = document.getElementById("smile-curve"),
      istem = document.getElementById("i-stem"),
      dot1 = document.getElementById("dot-1"),
      dot2 = document.getElementById("dot-2"),
      ptranscurve = document.getElementById("p-transition-curve"),
      pstem = document.getElementById("p-stem"),
      pcurve = document.getElementById("p-curve"),
      logoAnim = document.getElementById("logo-anim");


    // Intro animations
    let tlAnimatePi = gsap.timeline({ delay: 0.72 });

    function animatePI() {
      // reset timelines, tweens, delayed calls for this aniimation's parent container
      tlAnimatePi.clear();

      tlAnimatePi.set(istem, { opacity: 1, drawSVG: "70 200" })
        .set(hstem, { opacity: 1, drawSVG: "0% 100%" })
        .set(dot1, { opacity: 1, scaleX: 1, scaleY: 1, y: 0 })
        .set(dot2, { opacity: 1, scaleY: 0 })
        .set(hcurve, { opacity: 1, drawSVG: "0% 100%" })
        .set(smilecurve, { opacity: 1, drawSVG: "0%" })
        .set(ptranscurve, { opacity: 1, scaleX: 1, drawSVG: "0% 0%" })
        .set(pstem, { opacity: 1, drawSVG: "0% 0%" })
        .set(pcurve, { opacity: 1, drawSVG: "0% 0%" })
        // begin animation
        .to(logoAnim, { duration: 0.0415, visibility: "visible", opacity: 1, y: 0, ease: "power1.out" })
        .fromTo(hstem, { duration: 0.2, drawSVG: "0% 100%" }, { drawSVG: "100% 100%", ease: "power2.inOut" }, "+=0.4")
        .fromTo(smilecurve, { duration: 0.2, drawSVG: "100% 100%" }, { drawSVG: "100% 0%", ease: "power2.inOut" }, "+=0.1")
        .to(hcurve, { duration: 0.2, drawSVG: "100% 100%", ease: "power3.in" })
        .to(smilecurve, { duration: 0.2, drawSVG: "0%", ease: "power3.out" })
        .to(istem, { duration: 0.2, drawSVG: "30 200", ease: "power3.out" }, "-=0.1")
        .to(dot1, { duration: 0.15, y: -12, ease: "power3.out" }, "-=0.18")
        .to(istem, { duration: 0.2, drawSVG: "200 200", ease: "power3.out" }, "+=0.8")
        .add("balldrop")
        // dot 2 first appearance
        .to(smilecurve, { duration: 0.1, drawSVG: "100%", ease: "power3.out" }, "balldrop-=0.2")
        .to(dot1, { duration: 0.2, y: 158, scaleY: 0.5, transformOrigin: "50% 100%", ease: "power3.out" }, "balldrop-=0.2")
        .to(dot2, { duration: 0.1, scaleY: 0, transformOrigin: "50% 100%", ease: "elastic.out(1, 0.3)" }, "balldrop-=0.2")
        .to(dot2, { duration: 0.1, scaleY: 1.1, transformOrigin: "50% 100%", ease: "power3.out" }, "balldrop-=0.1")
        .to(dot2, { duration: 0.1, scaleY: 1, transformOrigin: "50% 100%", ease: "power3.out" }, "balldrop-=0.1")
        // dot 1 second bounce fun
        .to(dot1, { duration: 1, y: 158, scaleY: 1, transformOrigin: "50% bottom", ease: "elastic.out(1, 0.3)" }, "balldrop")
        // dot 2 spring load before second bounce
        .to(dot2, { duration: 0.5, scaleY: 0.81, transformOrigin: "50% 100%", ease: "power3.out" })
        .to(dot2, { duration: 0.5, y: -20, scaleY: 1.1, transformOrigin: "50% 100%", ease: "expo.out" })
        .to(dot2, { duration: 0.2, y: 0, scaleY: 0.5, transformOrigin: "50% 100%", ease: "expo.out" })
        .add("bounceByeByeBooBah")
        .to(dot2, { duration: 0.4, y: 0, scaleY: 1, transformOrigin: "50% 100%", ease: "back.out(1.7)" }, "bounceByeByeBooBah")
        .to(dot1, { duration: 0.2, scaleX: 0.9, scaleY: 2, opacity: 0, transformOrigin: "50% 100%" }, "bounceByeByeBooBah")
        .to(istem, { duration: 0.2, drawSVG: "200", ease: "power2.out" }, "bounceByeByeBooBah")
        .to(ptranscurve, { duration: 0.1, drawSVG: "100%", ease: "power3.out" }, "bounceByeByeBooBah")
        .fromTo(pstem, { duration: 0.1, drawSVG: "100% 100%" }, { drawSVG: "100% 0%", ease: "power3.out" }, "bounceByeByeBooBah")
        .to(dot2, { duration: 0.2, y: 0, scaleY: 1, transformOrigin: "50% 100%", ease: "back.out(1.7)" }, "bounceByeByeBooBah+=0.1")
        .to(ptranscurve, { duration: 0, scaleX: -1, transformOrigin: "50%" }, "bounceByeByeBooBah+=0.1")
        .to(ptranscurve, { duration: 0.1, drawSVG: "0% 0%", ease: "power3.out" }, "bounceByeByeBooBah+=0.2")
        .to(smilecurve, { duration: 0.1, drawSVG: "0% 0%", ease: "power3.out" }, "bounceByeByeBooBah+=0.2")
        .to(pcurve, { duration: 0.2, drawSVG: "100% 0%", ease: "power3.out" }, "bounceByeByeBooBah+=0.2")
        .call(logoClickListener, this, null);

      // console.log("tlAnimatePi duration: " + tlAnimatePi.duration());

    }
    animatePI();

    // Replay PI logo anim on click
    function logoClickListener() {
      // Replay PI logo anim on click
      logoAnim.addEventListener("click", animatePI);
    }

  }
  onLeaveCompleted() {
    // ...
    // console.log('ContactRenderer onLeaveCompleted');
  }
}

// Exports
export default ContactRenderer;