// Imports
import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { hideAllPixels, showAllPixels } from './pixel-takeover';

// Create Custom Transition
class ContactTransition extends Highway.Transition {
  in({ from, to, done }) {
    // console.log("contact-transition in funk");
    // Transition animation
    gsap.fromTo(to, { opacity: 0 }, {
      opacity: 1, duration: 0.5, delay: 0.9, onComplete: function () {
        from.remove();
        hideAllPixels();
        done();
      }
    });
  }

  out({ from, done }) {
    // console.log("contact-transition out funk");
    // Transition animation
    showAllPixels();
    // I want to have the contact page be removed after the home page has finished animating in
    done();
  }
}

// Export dat schnizz
export default ContactTransition;